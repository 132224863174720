import React from 'react'

import FourOFour from '../components/pages/FourOFour'

const FourOFourPage: React.FC = () => {
  return (
    <main>
      <FourOFour />
    </main>
  )
}

export default FourOFourPage
