import React from 'react'
import Link from 'next/link'

import Header from '../../Header'
import { routes } from '../../../routes'
import styles from './styles.module.scss'

const FourOFour: React.FC = () => {
  return (
    <>
      <div className={styles.background}>
        <Header />

        <div className={styles.content}>
          <h1 className={styles.title}>Something is missing.</h1>

          <h2 className={styles.text}>
            We can&apos;t seem to find the page you&apos;re looking for.
          </h2>

          <Link href={routes.home}>
            <a className={styles.link}>Back To Homepage</a>
          </Link>
        </div>
      </div>
    </>
  )
}

export default FourOFour
